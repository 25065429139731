
.white-select {
    color: white;
    /* 文字色 */
    .MuiSelect-select { 
      color: white;
    }
  
    /* 枠線 */
    & .MuiOutlinedInput-notchedOutline {
      border-color: white !important;
    }
  
    /* 三角ボタン (icon) */
    & .MuiSelect-icon {
      color: white; 
    }
  
    /* ホバー時 */
    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: lightgray; // 例: ホバー時に枠線を薄い灰色にする
    }
  
    /* フォーカス時 */
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: white; // 例: フォーカス時に枠線を白にする
    }

    &.MuiInputBase-root {
      color: white;;
    }

    &.MuiOutlinedInput-root {
      color: white;
    }
    
    &.MuiInputLabel-shrink {
      color: transparent !important;
    }
  }

.vendor-dashboard {
  &__button {
    width: 70%;
    margin-top: 20px;
    background-color: white !important;
  }
}