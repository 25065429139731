.slider {
    &_publication {
        height: 95% !important;
    }
    &_images {
        .slick-dots li button:before {
            color: grey !important; /* ドットの色を変更 */
          }
          
        .slick-dots li.slick-active button:before {
            color: orange !important; /* アクティブなドットの色を変更 */
        }
    }
}
.slick-list {
    height: 100% !important;
}