.vendor-management {
  &__button {
    height: 20vw;
    width: 20vw;
    border: solid 4px #65a7e9 !important;
    border-radius: 14px !important;
    display: flex;
    flex-direction: column;
    &__Main {
        border: solid 4px #65a7e9 !important;
        border-radius: 14px !important;
        display: flex;
        height: 8vw;
        width: 55vw;
        flex-direction: row;
        justify-content: space-evenly;
    }
    &__Sub {
        border: solid 4px #65a7e9 !important;
        border-radius: 14px !important;
        display: flex;
        height: 8vw;
        width: 10vw;
    }
  }
  &__slider {
    width: 100vw;
    &__content {
      margin-top: 6vh;
      margin-left: auto;
      margin-right: auto;
      display: flex !important;
      flex-direction: row;
      justify-content: space-around
    }
  }
}
@media screen and (min-width: 768px)
{
  .vendor-management {
    &__slider {
      &__content {
        width: 30vw !important;
      }
    }
  }  
}
