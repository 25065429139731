.vendor-login {
    &__card {
      // Cardコンポーネントのカスタマイズ
      box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
      border-radius: 8px;
      padding: 24px;
  
      @media (max-width: 600px) {
        padding: 16px;
      }
    }
    &__image {
      width: 70%;
      display: flex;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 2%;
    }
  }